import React from 'react';
import ServicesSlider from './ServicesSlider';

const OurServices = () => {

  return (
    <div style={{
      display: "flex",
      flexDirection: "column"
    }}>
        <ServicesSlider />

    </div>
  );
};

export default OurServices;
